.container {
  width: 100%;
  height: 97vh;
  margin-left:2px;
  position: relative;

  background-image: url(../LOGO.png);

  /* 设置背景图片居中 */
  background-position: center;
  /* 设置背景图片不重复 */
  background-repeat: no-repeat;
  /* 设置背景图片大小，可选 */
  background-size: 280px 140px;
  background-color: #f3f2ed;

  padding-top: 20px;
display: flex;
flex-direction: column;
align-items: center;
.projectLogo {
    width: 300px;
  font-family: 'Helvetica', sans-serif; /* 更改为更加现代的字体 */
  font-size: 24px;
  font-weight: bold;
  color: #ffffff; /* 使用白色字体 */
  background-color:#997b66;
  /* 使用蓝色渐变背景 */
  padding: 16px 30px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2) ;
  text-shadow: 4px 4px 2px rgba(0, 0, 0, 0.3); /* 轻微文字阴影 */
  text-align: center;
  margin: 0 auto;
  margin-bottom: 15px;
  border-radius: 20px;
}
  .proje2ctLogo {
    width: 100%;
    font-family: 'Helvetica', sans-serif; /* 更改为更加现代的字体 */
    font-size: 20px;
    color: #000; /* 使用白色字体 */
    padding: 10px 30px;
    border-radius: 8px; /* 增大边角弧度 */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3); /* 轻微文字阴影 */
    text-align: center;
    margin: 0 auto;
    margin-bottom: 15px;
    width: fit-content;
    border-bottom:2px solid #000;
    z-index: 100;
  }
  .aiContainer {
    overflow: scroll;
    width: 90%;
    height: calc(100vh - 160px);
    box-sizing: border-box;
    padding: 40px 0;
    :global(.ant-list-empty-text) {
      display: none;
    }
    .userMessage {
      display: flex;
      align-items: start;
      flex-direction: row; /* 用户消息右对齐 */
      margin-bottom: 20px;
      .messageContentWrapper {
        width: 100%;
        display: flex;
        align-items: start;
        flex-direction: row;
        font-size: 18px;
          .avatar {
            width: 40px;
            height: 40px;
            font-size: 20px;
            display: flex;
            color: #000;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            font-weight: 600;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
          }
        .contentWrapper {
          display: flex;
          flex-direction: column;
          align-items: start;
          flex:1;
          margin-left:10px;
          position: relative;
          .titleInfo {
            font-weight: 500;
            font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            font-size: 12px;
            line-height: 35px;
            margin-left: 10px;
            color: #000;
            font-weight:bold;

          }
          .messageContent {
            position: relative;
            background-color: #e1e1e1;
            font-weight: 500;
            font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            font-size: 14px;
            line-height: 35px;
            padding:5px 10px;
            border-radius: 10px;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
            max-width: calc(100% - 140px);
            flex-wrap: wrap;
            word-break: break-word;
            .messageContentText {
              font-size: 16px;
              p {
                margin: 0;
                padding: 0;
              }
            }
          }
        }

      }
    }

    .assistantMessage {
      display: flex;
      align-items: start;
      flex-direction: row; /* 助理消息左对齐 */
      margin-bottom: 20px;
      .messageContentWrapper {
        width: 100%;
        display: flex;
        align-items: start;
        flex-direction: row-reverse; /* 助理消息左对齐 */
        .avatar {
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #000;
          font-weight: 600;
          font-size: 14px;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);

        }
        .contentWrapper {
          display: flex;
          flex-direction: column;
          align-items: end;
          flex:1;
          margin-right:10px;
          position: relative;
          .titleInfo {
            font-weight: 500;
            font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            font-size: 12px;
            line-height: 35px;
            text-align: right;
            margin-right: 10px;
            color:#000;
            font-weight:bold;

          }
          .messageContent {
            background-color: #dfd6c8;
            font-size: 14px;
            color:#000;
            line-height: 25px;
            font-weight: 500;
            padding: 5px 10px;
            border-radius: 10px;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
            font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            max-width: calc(100% - 140px);
            flex-wrap: wrap;
            word-break: break-word;
            .messageContentText {
              font-size: 16px;
              p {
                margin: 0;
                padding: 0;
              }
            }
          }
        }
      }
    }
  }
  .search {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* min-height: 120px; */
    /* padding-bottom:30px; */
    box-sizing: border-box;
    :global(.ant-input-group-wrapper) {
      width: 600px;
      transform: scale(1.4);
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    }
    .searchSend {
      width: 90%;
    }
  }
  .select {
    position: absolute;
    top: 30px;
    left: 100px;
    width: 300px;
    margin-right: 200px;
  }
  .switch {
    position: absolute;
    top: 35px;
    right: 100px;
    transform: scale(1.4);
  }
  .messageContent {
    img {
      width: 300px;
    }

    iframe {
      width: 100%;
      height: 300px;
    }


  }
}

.assistantButton {
  font-size: 20px;
  position: absolute;
  bottom:0;
  left:-24px;
  button {
    margin-right: 10px;
    border-radius: 20px;
    font-size: 12px;
    color:#000

  }
}

.userButton {
  font-size: 20px;
  position: absolute;
  bottom:0;
  right:-24px;
  button {
    background-color: #dfd6c8;
    color: #333;
    border-radius: 20px;
    border: 1px solid #ccc;
    margin-right: 10px;
    font-size: 12px;
  }
}
.renderTitleWrapper {
  display: flex;
  align-items: center;
}

.renderTitle {
  margin-right:20px;
}

.renderTitleLogo {
  width: 10px;
  height: 10px;
  margin-right: 10px;
  border-radius: 50%;
 }
 .chatUsage {
  position:absolute;
  bottom:0;
  transform: translateY(100%);
  left:0;
  font-size: 12px;
  border-radius: 20px;
 }
 .chatUsageMoneyIcon {
  position:absolute;
  bottom:5px;
  right:5px;
  background-color: #f56a00;
  border-radius: 50%;
  color:#fff;
  font-size: 18px;
 }
 .uploadImages {
  width: 50px;
  height:50px;
 }